@import './variables.scss';
@import './functions.scss';

/*
	https://type-scale.com/
	https://seek-oss.github.io/capsize/
*/

html, body {
	font-size: #{$font-size-base + 'px'};
	font-family: $font_body;
	line-height: lh(1);
	font-weight: 400;
}

h1, h2, h3, h4 {
	font-family: $font_headlines;
	font-weight: 600;
	letter-spacing: -0.025em;
}

// https://type-scale.com/
h1 {
	@include vr_font($font-size-4);
}

h2 {
	@include vr_font($font-size-3);
}

h3 {
	@include vr_font($font-size-2);
}

h4 {
	@include vr_font($font-size-1);
}

.small_text {
	@include vr_font($font-size-m1);
}

.smaller_text {
	@include vr_font($font-size-m2);
}

.smallest_text {
	@include vr_font($font-size-m3);
}