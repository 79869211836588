@import './variables.scss';

@mixin vr_font($multiplier, $no_next_margin: true) {
	$max_lines: ceil($multiplier / $font_base_line-height);
	$extra_margin: ($max_lines * $font_base_line-height) - $multiplier;
	font-size: #{ $multiplier + 'rem' };
	line-height: #{ ($max_lines * $font_base_line-height) + 'rem'};
	margin: #{$font_base_line-height + 'rem'} 0 0 0;
	padding: 0;
}

@function lh($multiplier) {
	@return $font_base_line-height * $multiplier + rem;
}


@mixin stripe($color_1, $color_2, $size_x: 1em, $size_y: 1em) {
    background-image: linear-gradient(45deg, $color_1 25%, $color_2 25%, $color_2 50%, $color_1 50%, $color_1 75%, $color_2 75%, $color_2 100%);
    background-size: $size_x $size_y;
}

@function hsb_modify($hsb_color, $hue, $saturation, $brightness) {
	@return [nth($hsb_color, 1) + $hue, nth($hsb_color, 2) + $saturation, nth($hsb_color, 3) + $brightness];
}
  
@function hsb($hsb_color, $alpha: 1) {
	$h-hsb: nth($hsb_color, 1);
	$s-hsb: nth($hsb_color, 2);
	$b-hsb: nth($hsb_color, 3);

	// Convenience if a hue > 360 is provided
	@if $h-hsb > 360 {
		$h-hsb: $h-hsb - 360;
	}

	@if $b-hsb == 0 {
		@return hsla(0, 0, 0, $alpha);
	} @else {
		$l-hsl: ($b-hsb/2) * (2 - ($s-hsb/100));
		$s-hsl: ($b-hsb * $s-hsb) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
		@return hsla($h-hsb, $s-hsl, $l-hsl, $alpha);
	}
}

@mixin input($color, $light: 0) {
	display: block;
	box-sizing: border-box;
	width: 100%;
	margin: #{$base_line-height + 'rem'} 0 0 0;
	padding: .75em;
	border: .125em solid adjust-color($color,  $saturation: -75%, $alpha: -.80);
	border-radius: #{($base_line-height / 4) + 'rem'};
	background-color: adjust-color($color, $alpha: -.97);
  
	font-family: $font_form_elements;
	font-size: 1rem;
	line-height: 1.5rem;
	color: rgba(250,250,250, .75);
  
	transition: ease-in .1s;
  
	&:focus {
	  border-color: $color;
	  background-color: adjust-color($color, $alpha: -.85);
	  color: adjust-color($color_text_light, $lightness: -50%);
	  color: rgba(250,250,250, 1);
	}
  }

  @mixin stripe($color_1, $color_2, $size_x: 1em, $size_y: 1em, $degree: -45deg) {
//	background-image: linear-gradient($degree, $color_1 25%, $color_2 25%, $color_2 50%, $color_1 50%, $color_1 75%, $color_2 75%, $color_2 100%);
	background-image: linear-gradient($degree, $color_2 0%, $color_2 5%, $color_1 5%, $color_1 45%, $color_2 45%, $color_2 55%, $color_1 55%, $color_1 95%, $color_2 95%, $color_2 100%);
	background-size: $size_x $size_y;
  }