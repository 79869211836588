@import './styles/variables.scss', './styles/typography.scss', './styles/functions.scss';

html, body, div#root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

div#root {
}

body {
    background-color: hsb($color_background);
}

* {
    box-sizing: border-box;
}

h1.pretty {
    display: block;
    transform: rotate(-5deg);
    text-align: center;
    font-family: 'SNNeoNoire';
    font-size: 6rem;
    line-height: 6rem;
    margin: lh(2) auto;
    // background: -webkit-linear-gradient(#7effdd, #5fbde6);
    // background: -webkit-linear-gradient(hsb([164, 51, 100]), hsb([205, 65, 91]));
    background: -webkit-linear-gradient(hsb([160, 70, 100]), hsb([210, 70, 80]));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    //text-shadow: 2px 2px #f110cf;
    filter: drop-shadow(lh(.15) lh(.15) #f110cf); // drop-shadow(0 0 .15rem #7effdd);
    letter-spacing: 0rem;
    font-weight: 100;
}

div.IpInformation {
    background-color: hsb($color_background_lighter);
    position: relative;
    // opacity: .85;
    // backdrop-filter: blur(5px);
    border-radius: lh(.25);
    //border: 1px solid hsb(hsb_modify($color_background_lighter, 0, 0, 10));
    // box-shadow:
    //     lh(.25) lh(.25) lh(2) lh(0) rgba(0, 0, 0, .5),
    //     0 0 0 1px hsb(hsb_modify($color_background_lighter, 0, -20, 20)),
    //     0 0 0 2px black,
    //     inset 0 0 0 1px black;
    max-width: 50rem;
    margin: 6rem auto;
    padding: lh(1) lh(2);
    color: white;
    font-family: 'CartographCF';

    $border: 1px; //lh(.125);
    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -$border; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(to bottom right, hsb([300, 100, 100]), hsb([180, 100, 100]));
    }

    a, a:visited {
        color: hsb($color_pink);
    }

    dl {
        margin: 0;
        display: grid;
        grid-template-columns: 7.5rem auto;

        dt {
            grid-column: 1;
            justify-self: end;
            display: block;
            font-weight: 400;
            opacity: .5;
        }
        dt:after {
            content: ":";
        }

        dd{
            grid-column: 2;
            display: block;
            margin: 0 0 0 lh(.5);
            font-weight: 400;
        }

        span.ip {
            font-weight: 600;
        }
    }
}

div.message {
    width: 50rem;
    margin: 3rem auto;
    padding: 3rem;
    color: white;
    white-space: pre-line;
    @include stripe(transparent, hsb(hsb_modify($color_background, 0, -40, 20)));
    
        h1 {
        display: inline-block;
        padding: lh(.5);
        font-size: lh(3);
        line-height: lh(3);
        margin: 0;
        background: hsb($color_background);
    }
    p {
        clear: both;
        display: inline-block;
        padding: lh(.5);
        background: hsb($color_background);
    }

    dl {
        margin: lh(1) 0;
        display: grid;
        grid-template-columns: 7.5rem auto;
        background: hsb($color_background);
        font-family: 'CartographCF';

        dt {
            grid-column: 1;
            justify-self: end;
            display: block;
            font-weight: 100;
        }

        dd{
            grid-column: 2;
            display: block;
            margin: 0 0 0 lh(1);
        }
    }
}

// body {
//     background-color: hsb($color_background);
//     background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//     background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//     background-size: 400% 400%;
//     animation: gradient 15s ease infinite;
// }

// @keyframes gradient {
//     0% {
//         background-position: 0% 50%;
//     }
//     50% {
//         background-position: 100% 50%;
//     }
//     100% {
//         background-position: 0% 50%;
//     }
// }